.sideclass {
    background: #D7F5FF;
    // opacity: 0.3;
    border: 1px solid #E8E8E8;
    border-radius: 8px;
    padding: 0px 58px;
}

.c_ths,
.c_tds {
    cursor: pointer;
    padding: 10px 0;
    margin: 0;
    display: flex;

    .fa-list {
        cursor: move;
    }

    >* {
        padding-left: 15px;
        padding-right: 15px;

        &.question {
            width: 300px;
            min-width: 300px;
        }

        &.action {
            margin-left: auto;
            min-width: 170px;
        }
    }
}

.c_ths {
    font-weight: 600;
}

.answere {
    display: inline-block;
    max-width: 300px;
}

.checkList {
    label {
        margin-right: 14px;
    }
}

.roleForm {
    >* {
        >label {
            font-weight: 600;
        }
    }
}

#questionModal,
#viewQuestionModal {
    .modal-dialog {
        max-width: 900px;
    }
}

.deleteOption {
    color: #f44336;
    cursor: pointer;
}