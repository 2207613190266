.sideclass {
    background: #D7F5FF;
    // opacity: 0.3;
    border: 1px solid #E8E8E8;
    border-radius: 8px;
    padding: 0px 58px;
}

.checkList {
    label {
        margin-right: 14px;
    }
}

.roleForm {
    >* {
        >label {
            font-weight: 600;
        }
    }
}

#questionModal,
#viewQuestionModal {
    .modal-dialog {
        max-width: 900px;
    }
}

.deleteOption {
    color: #f44336;
    cursor: pointer;
}

.blogImage {
    width: 86px;
    height: 58px;
    object-fit: contain;
    border: 1px solid #80808024;
    border-radius: 17px;
}

label.lablefontcls {
    font-size: 14px;
}

i.fa.fa-times.imagcros {
    position: absolute;
    left: 3rem;
    top: 1rem;
    background: #ffffffcf;
    border-radius: 29px;
    width: 19px;
    height: 18px;
    display: none;
    z-index: 9999;
}

img.categoryImage {
    width: 75px;
    height: 52px;
    object-fit: cover;
    border: 1px solid #8080807d;
    border-radius: 17px;
}