@import '../../../scss/mixins';

.main-sidebar .nav .nav-item.active>.nav-link {
    background-color: #fff;
    color: #707070;
    font-size: 14px;
    font-weight: 500;
}

.hoverclass:hover {
    background: #D7F5FF;
    color: #197D9F !important;
    font-size: 14px;
    font-weight: 500;

}

.main-sidebar .nav .nav-item .nav-link {
    color: grey !important;
    font-weight: 400px;
    font-size: 14px;
    font-weight: 500;
}

.main-sidebar .nav .nav-item>.nav-link:hover,
.main-sidebar .nav .nav-item.active>.nav-link {
    color: #197D9F !important;
    background: #D7F5FF;
    font-weight: 400px;
    font-size: 14px;
    font-weight: 500;
}

.logocls {
    width: 100%;
    max-width: 100px !important;
}

.hoverclass {
    &.nobg {
        background: none !important;
    }
}

.submenu {
    display: none;
}

.active>.submenu {
    display: block;
}

.submenu>*>.hoverclass {
    padding-left: 25px;


}

.submenu.child2>*>.hoverclass {
    padding-left: 35px;
}