/* Calendar.css */

  
.calendar-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .calendar-nav button {
    padding: 5px 10px;
    cursor: pointer;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  table th,
  table td {
    border: 1px solid #ccc;
    padding: 8px;
    height: 30px;
    width: 30px;
  }
  
  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

   button:hover {
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

  button:focus {
    outline: 0px dotted;
    outline: 0px auto -webkit-focus-ring-color;
}
  
  .slots-popup {
    position: absolute;
    background-color: white;
    border: 1px solid #ccc;
    padding: 10px 10px;
    z-index: 1;
   box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
   border-radius: 5px;
  }

    .slots-popup p{
   margin: 0px;
  }
  


  
  .slots-popup ul {
    list-style: none;
    padding: 0px ;
    margin: 0px;
  }
  
.slots-popup li {
    margin-bottom: 5px;
    padding: 0px 10px 5px;
    font-size: 14px;
    color: #000;
}
  .has-slots {
    /* Define styles for dates with available slots */
    background-color: #327c9e;
    color: #000;
    border-radius: 0px;
    text-align: center;
    margin: 1px;
   
  }

   .has-slots button {
   
    color: #fff;
   
  }
  
  
.no-slots {
    background-color: rgb(255 255 255 / 0%);
}
  
.colorblue {
    font-weight: 600;
}

.height300{
    height: 400px;
}

.slots_lists {
    column-count: 2;
}

/* customcheckbox */

/* Hide the default checkbox */
.checkbox_new input {
  display: none;
}

.checkbox_new {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  font-size: 20px;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  margin-bottom: 10px;
}

/* Create a custom checkbox */
.checkmark {
  position: relative;
  top: 0;
  left: 0;
  height: 1.3em;
  width: 1.3em;
  background-color: #2196F300;
  border-radius: 0.25em;
  transition: all 0.25s;
}

/* When the checkbox is checked, add a blue background */
.checkbox_new input:checked ~ .checkmark {
  background-color: #327c9e;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  transform: rotate(0deg);
  border: 0.1em solid #327c9e;
  left: 0;
  top: 0;
  width: 1.05em;
  height: 1.05em;
  border-radius: 0.25em;
  transition: all 0.25s, border-width 0.1s;
}

/* Show the checkmark when checked */
.checkbox_new input:checked ~ .checkmark:after {
  left: 0.45em;
  top: 0.25em;
  width: 0.25em;
  height: 0.7em;
  border-color: #fff0 white white #fff0;
  border-width: 0 0.15em 0.15em 0;
  border-radius: 0em;
  transform: rotate(45deg);
}

span.ml-2.fs12 {
  font-size: 16px;
  position: relative;
  top: -2px;
}

.modal-title.h4 {
  font-size: 18px;
}

.fs16{
 font-weight: 600;
  font-size: 17px;
}

.slot_popsdata{
  column-count: 2;
}