@import './mixins';

// Colors
@import './colors';

body {
  font-family: $font1;

}

.tox {
  display: none;
}

.tox-notification--warning {
  display: none;
}

.tox-statusbar__path {
  display: none;
}

.filterFlex {
  display: flex;
  column-gap: 10px;
  row-gap: 10px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font2;
}

label {
  text-transform: capitalize;
}

i.fa.fa-edit {
  cursor: pointer;
}

.star {
  color: red;
}

i.fa.fa-arrow-left {
  color: #000;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #DBDDE0 !important;
  // text-transform: capitalize;
  white-space: nowrap;
}

body {
  font-family: 'Lato', sans-serif;


}

.inputWrapper {
  position: relative;

  .fa {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.modal-title,
.dropdown-item {
  text-transform: capitalize;
}

input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #000 !important;
  opacity: 1 !important;
  /* Firefox */
}

input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #000 !important;
  opacity: 1 !important;
}

.loaderDiv {
  z-index: 99999;
  background-color: #fff;
  align-items: center;
  justify-content: center;
  display: flex;
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}

.pageLoader {
  max-width: 50px;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #A0A4A8;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #A0A4A8;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #A0A4A8;
}

main.main {
  background-color: #f6f8fb !important;
}

// Loader start
img.loaderlogo {
  max-width: 50px;
  // animation: rotation 3s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

// Loader end

.btn {
  text-transform: capitalize;
}


.btn-outline-primary {
  color: #3f93af;
  border-color: #3f93af;
}

a {
  cursor: pointer;
}

.taableWrapper {
  max-height: calc(100vh - 250px);
}

button.btn.btn-primary.loginclass {
  background: #197D9F;
  border-radius: 100px;
  width: 100%;
  padding: 5px 15px;
}

@media(max-width:768px) {
  .taableWrapper {
    max-height: calc(100vh - 300px);
  }
}

.login-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  >.container {
    max-width: 500px;

    .shadow {
      border-top: 3px solid $primaryColor;
    }

    .login-logo {
      width: 100%;
      max-width: 150px;
    }
  }
}

.bginput {
  border: none !important;
  background: #F6F6F6 !important;
  border-radius: 8px !important;
}

.empty-box {
  padding: 15px;
  text-align: center;

  .icon-box {
    padding: 15px 30px;
    font-size: 26px;
    margin-bottom: 10px;
  }
}

h3.text-left.lgtext {
  font-weight: 600 !important;
  font-size: 33px;
  // line-height: 58px;
  color: #14424D !important;
}

.paraclss {
  font-weight: 400;
  font-size: 20px;
  line-height: 29px;
  color: #14424D !important;
}


::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #F6F6F6;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #F6F6F6;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #F6F6F6;
}


.modal {
  overflow-y: auto;

  .card-body,
  .modal-boldy {
    max-height: calc(100vh - 200px);
    overflow-y: auto;
  }
}

.upload-btn {
  position: relative;

  >input {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
}

.pagination {

  >li {
    padding: 2px;

    a {
      padding: 7px 10px;
      display: inline-block;
      border: 1px solid #E8E8E8;
      border-radius: 4px;
      font-size: 14px;
      min-width: 40px;
      text-align: center;
      color: #000;
      text-decoration: none;

    }

    &.active {
      a {
        background-color: $primaryColor;
        border-color: $primaryColor;
        color: #fff;
      }

    }

    &.disabled {
      a {
        border-color: #E8E8E8;
        color: #E8E8E8;
      }
    }
  }

}


.shine {
  background: #eee;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
  min-height: 20px;
  border-radius: 5px;
}


.shine.shineCard {
  height: 250px;
}

strong.shine {
  display: block;
  margin-bottom: 14px;
}


@keyframes shine {
  to {
    background-position-x: -200%;
  }
}


.react-pagination-js-default .is-active {
  display: inline-block;
  padding: 2px 12px !important;
  margin-right: 4px;
  border-radius: 3px;
  border: solid 1px #00809d !important;
  background: #00809d !important;
  font-weight: bold;
  text-decoration: none;
  color: #f0f0f0;
  cursor: pointer;
}

.react-pagination-js-default .page {
  display: inline-block;
  padding: 2px 12px !important;
  margin-right: 4px;
  border-radius: 3px;
  border: solid 1px #c0c0c0;
  background: #e9e9e9;
  font-weight: bold;
  text-decoration: none;
  color: #717171;
  cursor: pointer;
}

tr.tblclas th i {
  font-size: 13px;
  position: relative;
  top: 2px;
  margin: 0px 3px;
}

.mainarea {
  padding: 20px 20px 30px;
}

.activeItm {
  align-items: center;
  background: #a5b4ff3d;
  border-radius: 3px;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  padding: 2px 15px;
  text-transform: capitalize;
  font-size: 14px;
}
.missed {
  align-items: center;
  background: #bbb129;
  border-radius: 3px;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  padding: 2px 15px;
  text-transform: capitalize;
  font-size: 14px;
}
.done {
  align-items: center;
  background: #497c31;
  border-radius: 3px;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  padding: 2px 15px;
  text-transform: capitalize;
  font-size: 14px;
}

.terminated {
  align-items: center;
  background: #d30b0bb4;
  border-radius: 3px;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  padding: 2px 15px;
  text-transform: capitalize;
  font-size: 14px;
}
.deactiveItm {
  align-items: center;
  background: #e4970965;
  border-radius: 3px;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  padding: 2px 10px;
  text-transform: capitalize;
  font-size: 14px;
}
.pending {
  align-items: center;
  background: #d46d31dc;
  border-radius: 3px;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  padding: 2px 10px;
  text-transform: capitalize;
  font-size: 14px;
}


.paid {
  width: 80px;
  align-items: center;
  border-radius: 3px;
  cursor: pointer;
  display: inline-flex;
  font-size: 14px;
  justify-content: center;
  text-transform: capitalize;
  background-color: #77CA00;
}

.unpaid {
  width: 80px;
  align-items: center;
  border-radius: 3px;
  cursor: pointer;
  display: inline-flex;
  font-size: 14px;
  justify-content: center;
  text-transform: capitalize;
  background-color: #f74c5bb9;
}

.done2{
  width: 120px;
  color: black;
  background-color: #77CA00;
  text-align: center;
  border-radius: 3px;
  padding: 4px;
}

.pending2{
  width: 120px;
  color: black;
  background-color: #d19d0d;
  text-align: center;
  border-radius: 3px;
  padding: 4px;
}
.react-pagination-js-default {
  padding: 20px 0px;
  display: flex;
  justify-content: end;
}

.main_title h3 {
  margin: 0px;
  font-size: 20px;
  font-weight: 600;
}

.main_title {
  align-items: center;
}

.pprofile1 {
  box-shadow: 0px 0px 4px 0px #80808042;
  padding: 21px 22px;
}

.ViewUser {
  font-weight: 600;
  color: #197d9f;
  font-size: 20px;
  border-bottom: 1px solid #e3e0e0;
  padding-bottom: 15px;
  margin-bottom: 30px;
}

.border-none {
  border: none !important;
}

.head-bold p {
  font-weight: 600;
  font-size: 16px;
  color: #383536;
}

.head-dtls p {
  font-size: 15px !important;
}

.content-inner-details .row {

  padding-bottom: 5px;
  border-bottom: 1px solid rgb(233, 231, 231);
  margin-bottom: 20px;
}


.content-inner-details .row:last-child {
  border-bottom: none;
  /* Remove the border for the last child */
}

.pointer {
  cursor: pointer;
}



.prgresspills {
  width: 80px;
  display: block;
  text-align: center;
  border-radius: 3px;
  padding: 2px;
  color: #fff;
  font-size: 12px;
}

.accepted {
  background-color: #77CA00;
}

.accept1 {
  background-color: #53bb17;
  padding: 4px;
color: black !important;
  width: 120px;
  text-align: center;
  border-radius: 3px;
}

.img_testimonial{
  width: auto;
  height: 150px;
}

.pending1 {
  background-color: #d19d0d;
  padding: 4px;
  color: black !important;
  width: 120px;
  text-align: center;
  border-radius: 3px;
}
.pending {
  background-color: #F76B00;
}

.rejected {
  background-color: #BE0000;
}

.slots_lists_grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.heading_border {
  font-weight: 600;
  color: #197d9f;
  font-size: 20px;
  padding-bottom: 15px;
}

.bottomborder {
  border-bottom: 1px solid #e3e0e0;
  margin-bottom: 40px;
}

.btn_ace_reec {
  margin-bottom: 20px;
}

h5.headmain {
  font-size: 15px;
  font-weight: 500;
}

h6.subhead {
  font-size: 16px;
  color: #2c7c9f;
  font-weight: 600;
}

h6.subhead.statusview {
  background: #2c7c9f;
  color: #fff;
  padding: 5px;
  width: 100px;
  text-align: center;
  font-size: 12px;
  border-radius: 5px;
}


.btn_ace_reec .rejected {
  background: red !important;
  border: 1px solid #f60016 !important;
}

.btn_ace_reec button.btn.btn-primary.rejected:focus {
  color: #fff;
  background-color: #f60016 !important;
  border-color: #f60016 !important;
  box-shadow: none;
}


.table_admin .row {
  padding-bottom: 5px;
  border-bottom: 1px solid #e9e7e7;
  margin-bottom: 20px;
}

.cleint_questn h3 {
  font-size: 16px;
  font-weight: 600;
}

ul.patient_ans {
  padding: 0;
}

ul.patient_ans li {
  color: #f1f1f1;
  margin-bottom: 5px;
}

.clearfix {
  border-right: 2px solid transparent;
  background-color: transparent;
  border-radius: 5px;
}

.cleint_questn {
  padding: 15px 15px 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  margin-bottom: 20px;
}

.unread_chat{
  background-color: rgb(107, 166, 255);
  padding: 1px 5px;
  border-radius: 50px;
  height: 20px;
  width: 20px;
  font-size: 12px;
  color: #fff;
  display: inline-block;
  text-align: center;
  line-height: 18px;
  margin-left: 5px;
}


.admin_msg P {
  color: #FFF;
  background: #3a1aa4;
  width: 280PX;
  margin: 0 auto 20PX;
  border-radius: 50PX;
  font-size: 12px;
  padding: 4px;
}



.chat .chat-history::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.chat .chat-history  {
-ms-overflow-style: none;  /* IE and Edge */
scrollbar-width: none;  /* Firefox */
}

.message-data-name{
  color: #a8aab1 !important;
}

span.counting_msg {
  background: #fff;
  border-radius: 50px;
  height: 20px;
  width: 20px;
  color: #141414;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  padding: 2px;
  right: 5px;
  top: -12px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}


.shdaw_wraper {
  background: #ebeaea;
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 5px;
}

.flex_wrap{
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.flex_wrap span:nth-child(2){
  color: #141414;
  font-weight: 600;
}


.h600 {
  height: 500px;
}