.sideclass {
    background: #D7F5FF;
    // opacity: 0.3;
    border: 1px solid #E8E8E8;
    border-radius: 8px;
    padding: 0px 58px;
}

.backBtn{
    margin-right: 15px;
    cursor: pointer;
}

.checkList {
    label {
        margin-right: 14px;
    }
}

.roleForm {
    >* {
        >label {
            font-weight: 600;
        }
    }
}

.modal-dialog-md {
    max-width: 900px;
}

.deleteOption {
    color: #f44336;
    cursor: pointer;
}

i.fa.fa-trash {
    color: red;
}

.star {
    color: red;
}

.textcpitlize {
    text-transform: capitalize;
}
// .activeItm{
//     align-items: center;
//     background: #a5b4ff3d;
//     border-radius: 3px;
//     cursor: pointer;
//     display: inline-flex;
//     height: 24px;
//     justify-content: center;
//     min-width: 66px;
//     padding: 0 10px;
//     text-transform: capitalize;
// }
// .deactiveItm{
//     align-items: center;
//     background: #c4191965;
//     border-radius: 3px;
//     cursor: pointer;
//     display: inline-flex;
//     height: 24px;
//     justify-content: center;
//     min-width: 66px;
//     padding: 0 10px;
//     text-transform: capitalize;
// }