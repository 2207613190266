.cursor-poniter {
    cursor: pointer;
}

.customOption {
    >i {
        float: right;
        margin-bottom: -8px;
    }
}



.button-cover {
    display: inline-block;

    .knobs,
    .layer {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    .button {
        position: relative;
        width: 74px;
        height: 36px;
        overflow: hidden;
    }

    .button.r,
    .button.r .layer {
        border-radius: 100px;
    }

    .button.b2 {
        border-radius: 2px;
    }

    .checkbox {
        position: relative;
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
        opacity: 0;
        cursor: pointer;
        z-index: 3;
    }

    .knobs {
        z-index: 2;
    }

    .layer {
        width: 100%;
        background-color: #fcebeb;
        transition: 0.3s ease all;
        z-index: 1;
    }

}












/* Button 1 */
#button-1 .knobs:before {
    content: 'NO';
    position: absolute;
    top: 4px;
    left: 4px;
    width: 28px;
    color: #fff;
    font-size: 10px;
    font-weight: bold;
    text-align: center;
    line-height: 1;
    padding: 9px 4px;
    background-color: #f44336;
    border-radius: 50%;
    transition: 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15) all;
}

#button-1 .checkbox:checked+.knobs:before {
    content: 'YES';
    left: 42px;
    background-color: #03A9F4;
}

#button-1 .checkbox:checked~.layer {
    background-color: #ebf7fc;
}

#button-1 .knobs,
#button-1 .knobs:before,
#button-1 .layer {
    transition: 0.3s ease all;
}