.loginimg {
    width: 100%;
}

form.p-3.rounded.shadow {
    border-top: none;
}

img.loginimg.w-100 {
    height: 100%;
    object-fit: cover;
}

img.logimg {
    width: 100%;
    max-width: 167px;
    max-height: 100px;
    object-fit: cover;
}


.login-wrapper {
    .mainfromclss {
        max-width: 900px;
    }
}