.tosterclss {
    font-size: 16px;
    color: #7B330A;
}

.shineChart {
    height: 300px;
}

.legends {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 5px;
    column-gap: 10px;

    >* {
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 14px;

        .color {
            height: 12px;
            width: 12px;
            margin-right: 10px;
        }
    }
}

/* width */
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.react-datepicker__input-container {
    .form-control {
        padding-right: 25px;
    }
}

.funnel {
    display: flex;
    text-align: center;

    .funnelItem {
        flex: 1;
        border-right: 1px solid #d5d5d5;
        min-width: 120px;

        h5 {
            font-size: 12px;
            height: 97px;
            padding: 12px;
            margin: 0;
        }
    }

    .progressbar {
        display: flex;
        align-items: center;
        height: 250px;

        .progressline {
            background-color: #197d9f;
            width: 100%;
        }
    }

    .funnelBottom {
        font-size: 12px;
        padding: 12px;

        .percent {
            display: block;
            width: 100%;
        }
    }
}


.tabnoWrap {
    flex-wrap: nowrap;
    white-space: pre;
}

.nav-tabs .nav-link {
    color: #197d9f;
}

.nav-tabs .nav-link.active {
    color: #000;
}

.canvasjs-chart-credit {
    display: none !important;
}


.bgtoster {
    background: #FFECE1;
    border: 1px solid #CC8055;
    border-radius: 8px;
    padding: 9px 0px;
}

i.fa.fa-bell-o.ml-3.mr-2 {
    font-size: 24px;
}

.bgtable {
    // font-family: 'Lato';
    // font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    box-shadow: 1px 0px 20px 0px #efe7e7;
}

span.dsh_hedding {
    font-weight: 400;
}

.dash-title {
    position: relative;
    background-color: #f6f8fb;
    padding: 11px 19px;
    font-size: 28px;
    line-height: normal;
    border-radius: 6px;
    margin-bottom: 30px;
    color: #4A4A4A;
}

tr.tblclas {
    font-size: 14px;
    font-weight: 600;
}

table,

td {
    font-size: 14px;
    font-weight: 400;
    color: #4A4A4A;

}

.table th,
.table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #DBDDE0 !important;
}

.linkclass {
    color: #197D9F !important;
}

.bgtable {
    background: #FFFFFF;
    border-radius: 8px;
    padding: 15px 0px;
    overflow: auto;
}

.login-wrapper>.container .shadow {
    border-top: none;
}


.hedding {
    // font-family: 'Lato';
    //     font-style: normal;
    color: #25282B;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;

}

.dashboardFilters {
    text-align: right;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-end;
    column-gap: 10px;
    row-gap: 10px;

    .form-control {
        display: inline-block;
        width: auto;
    }
}

.canvasjs-react-chart-container {
    position: relative !important;

    &::before {
        content: "";
        position: absolute;
        height: 10px;
        background-color: white;
        width: 78px;
        z-index: 1;
        left: 0;
        top: 0;
    }
}

.searchDropdown {
    position: relative;

    .dropdown-menu {
        left: initial !important;
        right: 0;
        top: 100% !important;
        transform: initial !important;
        width: 300px;

        .searchText {
            width: calc(100% - 30px);
            margin-bottom: 10px;
            margin-left: auto;
            margin-right: auto;
            display: block;
        }

        .dropdown-item {
            white-space: initial;
        }
    }

    .list {
        max-height: 250px;
        overflow: auto;

        >* {
            &:nth-child(odd) {
                // background-color: red;
            }
        }
    }
}


.datepicker {
    position: relative;
    display: inline-block;

    .react-datepicker-wrapper {
        display: inline-block;
        width: auto;
    }
}