.profileImage {
    width: 80px;
    height: 80px;
    border-radius: 50%;
}

.profileImageLabel {
    margin: 0;
    position: relative;

    >i {
        position: absolute;
        bottom: -3px;
        right: -3px;
    }
}

// .pprofile1 {
//     box-shadow: 0px 0px 12px 0px #808080a8;
//     padding: 21px 22px;
// }

p.bg-light.rounded.py-2.fieldcls {
    background-color: #197d9f29 !important;

}

.ViewUser {
    font-weight: 600;
    color: #197d9f;
}

.edit_delete_wrap {
    right: 0;
    position: absolute;
    cursor: pointer;
    z-index: 9;
}