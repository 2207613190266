.sideclass {
    background: #D7F5FF;
    // opacity: 0.3;
    border: 1px solid #E8E8E8;
    border-radius: 8px;
    padding: 0px 58px;
}

.addDropdown {
    .dropdown-menu {
        left: initial !important;
        right: 0 !important;
        top: 100% !important;
        transform: none !important;
    }
}

.nowrap {
    white-space: nowrap;
}

.phoneInput {
    display: flex;
    justify-content: space-between;

    >*:first-child {
        width: 60px;
    }

    & *:last-child {
        width: calc(100% - 70px);
    }
}

.modal-dialog-md {
    max-width: 900px !important;
}

.edit_delete_wrap {
    right: 0;
    position: absolute;
    cursor: pointer;
    z-index: 9;
    margin-right: 36px;
}