.people-list {
  width: 30%;
  float: left;
}

.chatsingle {
    background: #F2F5F8;
}
.people-list .search {
  padding: 20px;
}

.timer_chat span{
  font-size: 16px;
  font-weight: 600;
  margin-left: 422px
}

.search input:focus-visible {
  outline: -webkit-focus-ring-color auto 0px;
}


.people-list input {
  border-radius: 3px;
  border: none;
  padding: 14px;
  color: #141414;
  background: #ffffff;
  width: 90%;
  font-size: 14px;
}
.people-list .fa-search {
  position: relative;
  left: -25px;
  color: #3a1aa4;
}
.people-list ul {
    padding: 20px;
    height: 437px;
    list-style: none;
     overflow-y: scroll;
}
.active_chat{
  border-right: 2px solid #3a1aa4;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 5px;
}
.people-list ul li {
  margin-bottom: 20px;
}
.people-list img {
  float: left;
}
.people-list .about {
  float: left;

}
.people-list .about {
  padding-left: 8px;
}
.people-list .status {
  color: #92959E;
}


.status {
    display: inline-block;
    padding: 0px;
    border-radius: 4px;
    font-weight: 400;
    background-color: #f2f5f8;
}
li.clearfix img {
    width: 45px;
    height: 45px;
    object-fit: cover;
    border-radius: 50px;
}
.clearfix{
  cursor: pointer;
}

.profile_btn img{
  margin-bottom: 15px;
}

.chat {
    width: 70%;
    float: left;
    background: #F2F5F8;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    color: #434651;
}


.chat .chat-header {
  padding: 15px;
  border-bottom: 2px solid white;
  background: #e0e0e0;
}
.chat .chat-header img {
  float: left;
}
.chat .chat-header .chat-about {
  float: left;
  padding-left: 10px;
  margin-top: 6px;
}
.chat .chat-header .chat-with {
  font-weight: bold;
  font-size: 16px;
}
.chat .chat-header .chat-num-messages {
  color: #92959E;
}
.chat .chat-header .fa-star {
  float: right;
  color: #D8DADF;
  font-size: 20px;
  margin-top: 12px;
}
.chat .chat-history {
  padding: 30px 30px 20px;
  border-bottom: 2px solid white;
  overflow-y: scroll;
  height: 360px;
}
.chat .chat-history .message-data {
  margin-bottom: 15px;
}
.chat .chat-history .message-data-time {
  color: #a8aab1;
  padding-left: 6px;
}
.chat .chat-history .message {
  color: white;
  padding: 18px 20px;
  line-height: 26px;
  font-size: 16px;
  border-radius: 7px;
  margin-bottom: 30px;
  width: 65%;
  position: relative;
  line-break: anywhere;
}
.chat .chat-history .message:after {
  bottom: 100%;
  left: 7%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: #afa7e896;
  border-width: 10px;
  margin-left: -10px;
}
.chat .chat-history .my-message {
  background: #afa7e896;
  color: #2e2c2c;
}
.chat .chat-history .other-message {
  background: #7a76c4;
}
.chat .chat-history .other-message:after {
  border-bottom-color: #7a76c4;
  left: 93%;
}
.chat .chat-message {
  padding: 10px;
  background: #e0e0e0;
}
.chat .chat-message textarea {
  width: 100%;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  resize: none;
}

.new_chats{
  border-right: 2px solid transparent;
    background-color: transparent;
    padding: 10px;
    border-radius: 5px;
}
.chat .chat-message .fa-file-o, .chat .chat-message .fa-file-image-o {
  font-size: 16px;
  color: gray;
  cursor: pointer;
}

.me_right{
  color: #ccc6ee;
  margin-right: 3px;
  font-size: 10px;
}
.chat .chat-message button {
  float: right;
  color: #2f29a4;
  font-size: 16px;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  font-weight: bold;
}
.chat .chat-message button:hover {
  color: #75b1e8;
}

.online, .offline, .me {
  margin-right: 3px;
  font-size: 10px;
}

.online {
  color: #afa7e896;
}

.offline {
  color: #E38968;
}

.me {
  color: #2f29a4;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.float-right {
  float: right;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

.chat-history ul {
    list-style: none;
}


.image_loader {
  text-align: center;
  padding: 30px 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f0e5e5;
  height: 40vh;
  margin-left: auto;
  border-radius: 10px;
  position: relative;
  top: -34px;
  width: 30%;
  margin-right: 30px;
  border: 1px solid #ccc;
}

/* Show loader when the image is loading */
.loading .loader {
  display: block;
}

/* Show image when it's loaded */
.loaded .image {
  display: block;
}



.clearfix img {
    height: 50px;
    border-radius: 50px;
}

.textrea_list{
  height: 45px;
}

.pointer_upload {
    position: absolute;
    right: 20px;
    top: 45%;
    transform: translateY(-50%);
}

.pointer_upload i.fa.fa-paperclip {
    color: grey;
}


.textrea_list:focus-visible {
    outline: #2f29a4 auto 1px;
}

.textrea_list {
    height: 50px;
}

.icon_blue {
    color: #2f29a4 !important;
    font-size: 25px;
    margin-left: 10px;
}