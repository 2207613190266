@import '../../../scss/mixins';

.main-navbar {
  background-color: #F6F8FB;
  color: #fff;
  padding-bottom: 20px;
  padding-left: 250px;

  &.min-sidebar {
    padding-left: 60px;
  }

  .nav-link {
    padding: 10px;
  }

  .nav-link-user {
    >img {
      width: 40px;
      height: 40px;
    }
  }
}

.headerSearch {
  position: relative;
  font-size: 14px;
  color: #000;

  .Searchbar {
    background: #FFFFFF;
    border-radius: 100px;
    border: none;
    padding: 10px 35px;
  }

  >i {
    position: absolute;
    top: 50%;
    color: #000;
    transform: translateY(-50%);
    cursor: pointer;

    &.fa-search {
      left: 10px;
    }

    &.fa-times {
      right: 10px;
    }
  }
}

.notification {
  color: #CACCCF;
  font-size: 27px;
  transform: rotate(29deg);
  margin-right: 22px;
  margin-top: 16px;
}

.reddot {
  background-color: red;
  padding: 2px 5px;
  border-radius: 14px;
  color: #ffff;
  font-size: 12px;
  font-weight: 600;
  position: absolute;
  top: -13px;
  right: -2px;
  border: 3px solid #ffff;
  transform: rotate(333deg);
}


.min-sidebar {
  ~div>.main-sidebar {
    width: 58px;
    min-width: 58px;

    // .sidebar-brand {
    //   width: 0;
    //   visibility: hidden;
    // }

    .nav-link span {
      display: none;
    }
  }

  ~div>main.main {
    width: calc(100% - 58px);
  }

    .main-sidebar .nav .nav-item {
  
      text-align: center !important;
    }


}


