.main12 {
    display: flex;
    width: 100%;
    height: 60vh;
    justify-content: center;
    align-items: center;
}

.backcls{
    background-image:url('https://wallpapers.com/images/hd/virtual-meeting-background-srj07387lg3e219s.jpg');
    background-position:100% 100%;
    height:"100%";
}